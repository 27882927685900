<template>
  <div>
    <v-row class="mb-3">
      <h2>Tipo de Integração: <b>Folha</b></h2>
    </v-row>
    <v-tabs
      color="primary"
      background-color="var(--v-background-base)"
      v-model="tab"
      class="mb-4"
    >
      <v-tab style="font-size: 22px">Obrigatórias</v-tab>
      <v-tab style="font-size: 22px">Avançadas</v-tab>
    </v-tabs>

    <v-form ref="form" lazy-validation>
      <v-tabs-items
        v-model="tab"
        grow
        style="background-color: var(--v-background-base)"
      >
        <v-tab-item>
          <v-row class="mt-2">
            <v-col cols="2"><label class="label">URL (hostname)*</label></v-col>
            <v-col cols="4"
              ><v-text-field
                outlined
                label="Hostname"
                :rules="[rule.required]"
                v-model="ftpParameter.url"
              ></v-text-field
            ></v-col>
          </v-row>
          <v-row>
            <v-col cols="2"><label class="label">Path*</label></v-col>
            <v-col cols="4"
              ><v-text-field
                outlined
                label="Path"
                :rules="[rule.required]"
                v-model="ftpParameter.path"
              ></v-text-field
            ></v-col>
          </v-row>
          <v-row>
            <v-col cols="2"><label class="label">Porta*</label></v-col>
            <v-col cols="4"
              ><v-text-field
                outlined
                label="Porta"
                type="number"
                :rules="[rule.required]"
                v-model="ftpParameter.port"
              ></v-text-field
            ></v-col>
          </v-row>
          <v-row>
            <v-col cols="2"><label class="label">Usuário*</label></v-col>
            <v-col cols="4"
              ><v-text-field
                outlined
                label="Usuário"
                :rules="[rule.required]"
                v-model="ftpParameter.user"
              ></v-text-field
            ></v-col>
          </v-row>
          <v-row>
            <v-col cols="2"><label class="label">Senha*</label></v-col>
            <v-col cols="4">
              <v-text-field
                v-model="ftpParameter.password"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[rule.required]"
                :type="showPassword ? 'text' : 'password'"
                label="Senha"
                outlined
                name="input-10-1"
                @click:append="showPassword = !showPassword"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="2"><label class="label">Cron Expression</label></v-col>
            <v-col cols="4"
              ><v-text-field
                outlined
                label="Cron Expression"
                v-model="ftpParameter.frequency"
              ></v-text-field
            ></v-col>
          </v-row>
          <v-row>
            <v-col cols="2"
              ><label class="label">File Filter Regex*</label></v-col
            >
            <v-col cols="4"
              ><v-text-field
                outlined
                label="File Filter Regex"
                v-model="ftpParameter.fileFilterRegex"
              ></v-text-field
            ></v-col>
          </v-row>
          <v-row>
            <v-col cols="2"
              ><label class="label">Path Filter Regex*</label></v-col
            >
            <v-col cols="4"
              ><v-text-field
                outlined
                label="Path Filter Regex"
                v-model="ftpParameter.pathFilterRegex"
              ></v-text-field
            ></v-col>
          </v-row>
          <v-row>
            <v-col cols="2"
              ><label class="label">Pasta arquivos coletados</label></v-col
            >
            <v-col cols="4"
              ><v-text-field
                outlined
                label="Pasta arquivos coletados"
                v-model="ftpParameter.pathCollectedFile"
              ></v-text-field
            ></v-col>
          </v-row>
        </v-tab-item>
        <v-tab-item>
          <v-row>
            <v-col cols="2"
              ><label class="label">Deletar arquivo original</label></v-col
            >
            <v-col cols="4">
              <v-btn-toggle v-model="ftpParameter.deleteFileOriginal" mandatory>
                <div style="background-color: var(--v-background-base)">
                  <v-btn
                    small
                    active-class="primary"
                    min-width="158"
                    :value="false"
                    class="mr-6"
                    >Não</v-btn
                  >
                  <v-btn
                    small
                    active-class="primary"
                    min-width="158"
                    :value="true"
                    class="mr-6"
                    >Sim</v-btn
                  >
                </div>
              </v-btn-toggle>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="2">
              <div>
                <label class="label">Diretório Destino</label>
              </div>
              <span>(Move Destination Directory)</span>
            </v-col>
            <v-col cols="4"
              ><v-text-field
                outlined
                label="Move Destination Directory"
                v-model="ftpParameter.moveDestinationDirectory"
              ></v-text-field
            ></v-col>
          </v-row>
          <v-row>
            <v-col cols="2">
              <div>
                <label class="label">SFTP Support</label>
              </div>
              <span>Private Key Path</span>
            </v-col>
            <v-col cols="4"
              ><v-text-field
                outlined
                label="Private Key Path"
                v-model="ftpParameter.sftp.supportPrivateKeyPath"
              ></v-text-field
            ></v-col>
          </v-row>
          <v-row>
            <v-col cols="2">
              <div>
                <label class="label">FTPS Support</label>
              </div>
              <div><span>- Keystore File</span></div>
              <div><span>- Password</span></div>
            </v-col>
            <v-col cols="4"
              ><v-text-field
                outlined
                label="Keystore File Password"
                v-model="ftpParameter.sftp.supportKeyStoreFilePassword"
              ></v-text-field
            ></v-col>
          </v-row>
          <v-row>
            <v-col cols="2">
              <div>
                <label class="label">FTPS Support</label>
              </div>
              <div><span>- Security Mode</span></div>
            </v-col>
            <v-col cols="4">
              <v-btn-toggle
                v-model="ftpParameter.sftp.supportSecurityMode"
                mandatory
              >
                <div style="background-color: var(--v-background-base)">
                  <v-btn
                    small
                    active-class="primary"
                    value="IMPLICIT"
                    min-width="158"
                    class="mr-6"
                    >Implicit</v-btn
                  >
                  <v-btn
                    small
                    active-class="primary"
                    value="EXPLICIT"
                    min-width="158"
                    class="mr-6"
                    >Explicit</v-btn
                  >
                </div>
              </v-btn-toggle>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="2">
              <div>
                <label class="label">FTPS Support</label>
              </div>
              <div><span>- Data channel</span></div>
              <div>
                <span
                  >- Protection Level (Clear, Safe, Confidential, Private)</span
                >
              </div>
            </v-col>
            <v-col cols="4"
              ><v-text-field
                outlined
                label="Protection Level (Clear, Safe, Confidential ou Private)"
                v-model="ftpParameter.sftp.supportDataChannelProtectionLevel"
              ></v-text-field
            ></v-col>
          </v-row>

          <v-row>
            <v-col cols="2">
              <div>
                <label class="label">Tamanho do Buffer</label>
              </div>
            </v-col>
            <v-col cols="4"
              ><v-text-field
                outlined
                label="Tamanho do Buffer"
                v-model="ftpParameter.sizeBuffer"
              ></v-text-field
            ></v-col>
          </v-row>

          <v-row>
            <v-col cols="2">
              <div>
                <label class="label">Modo de Conexão</label>
              </div>
            </v-col>
            <v-col cols="4">
              <v-btn-toggle v-model="ftpParameter.conectionMode" mandatory>
                <div style="background-color: var(--v-background-base)">
                  <v-btn
                    small
                    active-class="primary"
                    min-width="158"
                    class="mr-6"
                    value="ACTIVE"
                    >Ativo</v-btn
                  >
                  <v-btn
                    small
                    active-class="primary"
                    min-width="158"
                    class="mr-6"
                    value="PASSIVE"
                    >Passivo</v-btn
                  >
                </div>
              </v-btn-toggle>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="2">
              <div>
                <label class="label">Use UTF-8 Encoding</label>
              </div>
            </v-col>
            <v-col cols="4">
              <v-btn-toggle v-model="ftpParameter.useUtf8Encoding" mandatory>
                <div style="background-color: var(--v-background-base)">
                  <v-btn
                    small
                    active-class="primary"
                    min-width="158"
                    :value="true"
                    class="mr-6"
                    >Sim</v-btn
                  >
                  <v-btn
                    small
                    active-class="primary"
                    min-width="158"
                    :value="false"
                    class="mr-6"
                    >Não</v-btn
                  >
                </div>
              </v-btn-toggle></v-col
            >
          </v-row>

          <v-row>
            <v-col cols="2">
              <div>
                <label class="label">Transfer mode</label>
              </div>
            </v-col>
            <v-col cols="4">
              <v-btn-toggle v-model="ftpParameter.tranferMode" mandatory>
                <div style="background-color: var(--v-background-base)">
                  <v-btn
                    small
                    active-class="primary"
                    min-width="158"
                    class="mr-6"
                    value="BINARY"
                    >Binary</v-btn
                  >
                  <v-btn
                    small
                    active-class="primary"
                    min-width="158"
                    class="mr-6"
                    value="ASCII"
                    >ASCII</v-btn
                  >
                </div>
              </v-btn-toggle></v-col
            >
          </v-row>

          <v-row>
            <v-col cols="2">
              <div>
                <label class="label">Proxy configuration service</label>
              </div>
            </v-col>
            <v-col cols="4"
              ><v-text-field
                outlined
                label="Proxy Configuration Service"
                v-model="ftpParameter.proxy.proxyConfigurationService"
              ></v-text-field
            ></v-col>
          </v-row>

          <v-row>
            <v-col cols="2">
              <div>
                <label class="label">Proxy Host</label>
              </div>
            </v-col>
            <v-col cols="4"
              ><v-text-field
                outlined
                label="Proxy Host"
                v-model="ftpParameter.proxy.host"
              ></v-text-field
            ></v-col>
          </v-row>

          <v-row>
            <v-col cols="2">
              <div>
                <label class="label">Proxy Port</label>
              </div>
            </v-col>
            <v-col cols="4"
              ><v-text-field
                outlined
                label="Proxy Port"
                type="number"
                v-model="ftpParameter.proxy.port"
              ></v-text-field
            ></v-col>
          </v-row>

          <v-row>
            <v-col cols="2">
              <div>
                <label class="label">Proxy Type</label>
              </div>
            </v-col>
            <v-col cols="4"
              ><v-text-field
                outlined
                label="Proxy Type"
                v-model="ftpParameter.proxy.type"
              ></v-text-field
            ></v-col>
          </v-row>

          <v-row>
            <v-col cols="2">
              <div>
                <label class="label">Proxy Username</label>
              </div>
            </v-col>
            <v-col cols="4"
              ><v-text-field
                outlined
                label="Proxy Username"
                v-model="ftpParameter.proxy.username"
              ></v-text-field
            ></v-col>
          </v-row>

          <v-row>
            <v-col cols="2">
              <div>
                <label class="label">Proxy Password</label>
              </div>
            </v-col>
            <v-col cols="4"
              ><v-text-field
                outlined
                label="Proxy Password"
                v-model="ftpParameter.proxy.password"
              ></v-text-field
            ></v-col>
          </v-row>
        </v-tab-item>
      </v-tabs-items>
    </v-form>
    <v-footer>
      <v-row class="d-flex justify-end mt-10">
        <v-btn
          class="mr-6"
          x-large
          outlined
          color="textPrimary"
          width="250px"
          @click="onClickCancel()"
        >
          Cancelar
        </v-btn>
        <v-btn
          v-if="this.isLast"
          class="white--text mr-10"
          x-large
          color="textPrimary"
          width="250px"
          @click="isEdit ? onClickEdit() : onClickSave()"
        >
          Salvar
        </v-btn>
        <v-btn
          v-else
          class="white--text mr-10"
          x-large
          color="textPrimary"
          width="250px"
          @click="onClickNext()"
        >
          Próximo
        </v-btn>
      </v-row>
    </v-footer>
  </div>
</template>
<script>
import Rules from '@/shared/validators/formRules';

export default {
  name: 'PAYROLL',
  data() {
    return {
      tab: null,
      isEdit: false,
      configs: [],
      editConfigs: [],
      showPassword: false,
      ftpParameter: {
        pathCollectedFile: '',
        conectionMode: 'ACTIVE',
        deleteFileOriginal: true,
        fileFilterRegex: '',
        frequency: '',
        id: 0,
        moveDestinationDirectory: '',
        password: '',
        path: '',
        pathFilterRegex: '',
        port: '',
        proxy: {
          host: '',
          password: '',
          port: '',
          proxyConfigurationService: '',
          type: '',
          username: '',
        },
        sftp: {
          supportDataChannelProtectionLevel: '',
          supportKeyStoreFilePassword: '',
          supportPrivateKeyPath: '',
          supportSecurityMode: 'IMPLICIT',
        },
        sizeBuffer: '',
        status: 'ENABLED',
        tranferMode: 'BINARY',
        url: '',
        useUtf8Encoding: true,
        user: '',
      },
    };
  },

  props: {
    isLast: {
      type: Boolean,
      required: true,
    },
  },

  mounted() {
    this.verifyCollectorEdit();
  },

  methods: {
    onClickCancel() {
      this.$router.push('/collectors');
    },
    onClickNext() {
      if (this.$refs.form.validate()) {
        if (this.isEdit) {
          const collectorsConfigs = [];
          const ftpParameter = {
            name: this.configs.name,
            cronExpression: this.ftpParameter.frequency,
            type: this.configs.type,
            ftpParameter: this.ftpParameter,
          };
          delete this.configs.emailParameter;
          collectorsConfigs.push(ftpParameter);

          sessionStorage.setItem('collectorsConfigs', JSON.stringify(collectorsConfigs));
        } else {
          const ftpParameter = {
            name: JSON.parse(sessionStorage.getItem('collector')).collectorName,
            cronExpression: this.ftpParameter.frequency,
            type: 'PAYROLL',
            ftpParameter: this.ftpParameter,
          };
          this.configs.push(ftpParameter);
          sessionStorage.setItem('collectorsConfigs', JSON.stringify(this.configs));
        }
        this.$emit('onClickNext');
      } else {
        this.tab = 0;
      }
    },
    onClickSave() {
      if (this.$refs.form.validate()) {
        const ftpParameter = {
          name: JSON.parse(sessionStorage.getItem('collector')).collectorName,
          cronExpression: this.ftpParameter.frequency,
          type: 'PAYROLL',
          ftpParameter: this.ftpParameter,
        };
        this.configs.push(ftpParameter);
        sessionStorage.setItem(
          'collectorsConfigs',
          JSON.stringify(this.configs),
        );
        this.$emit('onClickSave');
      } else {
        this.tab = 0;
      }
    },
    onClickEdit() {
      if (this.$refs.form.validate()) {
        let ftpParameter = {};
        const collectorsConfigs = JSON.parse(sessionStorage.getItem('collectorsConfigs'));
        ftpParameter = {
          name: collectorsConfigs[0].name,
          cronExpression: this.ftpParameter.frequency,
          type: 'PAYROLL',
          ftpParameter: this.ftpParameter,
        };

        collectorsConfigs.push(ftpParameter);
        delete this.configs.emailParameter;
        sessionStorage.setItem('collectorsConfigs', JSON.stringify(collectorsConfigs));
        this.$emit('onClickEdit');
      } else {
        this.tab = 0;
      }
    },
    verifyCollectorEdit() {
      if (this.$route.query.isEdit) {
        this.isEdit = true;
        const session = JSON.parse(sessionStorage.getItem('editCollector'));
        const found = session.configs.find((config) => config.type === 'PAYROLL');
        if (found) {
          // eslint-disable-next-line prefer-destructuring
          this.configs = found;
          if (this.configs) {
            this.ftpParameter = this.configs.ftpParameter;
          }
        } else {
          // eslint-disable-next-line no-unused-expressions
          this.configs = JSON.parse(sessionStorage.getItem('collectorsConfigs'));
          this.editConfigs = JSON.parse(sessionStorage.getItem('collectorsConfigs'));
        }
      } else {
        this.configs = JSON.parse(sessionStorage.getItem('collectorsConfigs'));
      }
    },
  },
  created() {
    this.rule = new Rules();
  },
};
</script>
