<template>
  <v-container class="form">
    <v-row>
      <v-col cols="6">
        <h1 class="secondary--text">
          {{ isEdit ? "Editar Coletor" : "Configurar Novo Coletor" }}
        </h1>
      </v-col>
      <v-col cols="6">
        <!-- STEPPER -->
        <v-stepper v-model="step" class="stepper">
          <v-stepper-header>
            <template v-for="(n, index) in collector.types">
              <v-stepper-step
                :key="`${index}-step`"
                :complete="step > index"
                :step="index + 1"
              >
                {{ n && n === 'PAYROLL' ? 'FOLHA' : n }}
              </v-stepper-step>

              <v-divider
                v-if="index < collector.types.length - 1"
                :key="n"
              ></v-divider>
            </template>
          </v-stepper-header>
        </v-stepper>
      </v-col>
    </v-row>

    <div v-if="collectorType === 'client'">
      <v-row>
        <v-col cols="3" lg="3" md="4"><label>Grupo Financeiro</label></v-col>
        <v-col cols="2" lg="2" md="3" v-if="collector.contract"
          ><label>Contrato</label></v-col
        >
        <v-col cols="2" lg="2" md="3" v-if="collector.subContract"
          ><label>Sub-Contrato</label></v-col
        >
      </v-row>
      <v-row class="mb-6">
        <v-col cols="3" lg="3" md="4">
          <v-text-field
            v-model="collector.financialGroup.name"
            readonly
            disabled
            solo
          />
        </v-col>
        <v-col cols="2" lg="2" md="3" v-if="collector.contract">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <v-text-field
                  :value="contructorContractDescription(collector.contract.id)"
                  placeholder="Não informado"
                  readonly
                  disabled
                  solo
                  :loading="loadingContracts"
                />
              </div>
            </template>
            <span>{{ contructorContractDescription(collector.contract.id) }}</span>
          </v-tooltip>
        </v-col>
        <v-col cols="2" lg="2" md="3" v-if="collector.subContract">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <v-text-field
                  :value="contructorSubContractDescription(collector.subContract.id)"
                  placeholder="Não informado"
                  readonly
                  disabled
                  solo
                  :loading="loadingSubContracts"
                />
              </div>
            </template>
            <span>{{ contructorSubContractDescription(collector.subContract.id) }}</span>
          </v-tooltip>
        </v-col>
        <v-col
          cols="2"
          lg="3"
          md="3"
          v-if="isEdit && !notIncludeCollectorInFinalStep"
        >
          <v-btn
            large
            outlined
            block
            @click="insertMoreCollectorsDialog.show = true"
          >
            <v-icon left> fas fa-plus-circle </v-icon>
            Adicionar Coletores
          </v-btn>
        </v-col>
        <v-col cols="2" lg="2" md="3" v-if="isEdit && configsLength > 1">
          <v-btn large outlined block @click="deleteDialog.show = true">
            <v-icon left> fas fa-trash </v-icon>
            Excluir Coletor
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <div v-if="collectorType === 'carrier'">
      <v-row>
        <v-col cols="4"><label>Operadora</label></v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <v-text-field
            v-model="collector.insuranceCarrier.name"
            readonly
            disabled
            solo
          ></v-text-field>
        </v-col>
        <v-col cols="2" lg="3" md="4">
          <v-btn
            v-if="isEdit"
            large
            outlined
            @click="insertMoreCollectorsDialog.show = true"
          >
            <v-icon left> fas fa-plus-circle </v-icon>
            Selecionar Mais Coletores
          </v-btn>
        </v-col>
        <v-col cols="2" lg="2" md="3">
          <v-btn v-if="isEdit" large outlined @click="deleteDialog.show = true">
            <v-icon left> fas fa-trash </v-icon>
            Excluir Coletor
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <v-dialog
      :value="insertMoreCollectorsDialog.show"
      persistent
      max-width="1080"
    >
      <v-card class="pa-5">
        <v-card-title class="headline pl-3 ml-3">
          <v-row>
            <v-col cols="12" align="end">
              <v-icon
                large
                color="textPrimary"
                @click="insertMoreCollectorsDialog.show = false"
                >fas fa-times</v-icon
              >
            </v-col>
            <v-col class="text-h4" cols="12" style="color: #3b495b; font-weight: bold">Selecione Mais Coletores</v-col>
            <v-col class="text-h5" cols="12" style="color: #3b495b">
              <v-btn-toggle v-model="collector.types" borderless multiple dense group mandatory>
                <v-btn
                  v-show="currentCollector === 'EMAIL' || currentCollector === 'PAYROLL'|| currentCollector === 'API'"
                  value="FTP"
                  active-class="primary"
                  class="moreCollectorButtons"
                  min-width="120"
                >
                  FTP
                </v-btn>
                <v-btn
                  v-show="currentCollector === 'FTP' || currentCollector === 'PAYROLL'|| currentCollector === 'API'"
                  value="EMAIL"
                  active-class="primary"
                  class="moreCollectorButtons"
                  min-width="120"
                >
                  E-mail
                </v-btn>
                <v-btn
                  v-show="currentCollector === 'FTP' || currentCollector === 'EMAIL'|| currentCollector === 'API'"
                  value="PAYROLL"
                  active-class="primary"
                  class="moreCollectorButtons"
                  min-width="120"
                >
                  Folha
                </v-btn>
                <v-btn
                  v-show="currentCollector === 'FTP' || currentCollector === 'EMAIL'|| currentCollector === 'PAYROLL'"
                  value="API"
                  active-class="primary"
                  class="moreCollectorButtons"
                  min-width="120"
                >
                  API
                </v-btn>
              </v-btn-toggle>
            </v-col>
          </v-row>
        </v-card-title>

        <v-card-actions class="mt-10">
          <v-row class="mt-6">
            <v-spacer></v-spacer>
            <v-btn
              class="mr-3 pa-7 btn-dialog"
              color="#3B495B"
              outlined
              width="200px"
              @click="insertMoreCollectorsDialog.show = false"
            >
              Cancelar
            </v-btn>
            <v-btn
              class="mr-6 pa-7 btn-dialog"
              color="#3B495B"
              width="200px"
              style="color: white"
              @click="insertMoreCollectorsDialog.show = false; verifyLastStep();"
            >
              OK
            </v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog :value="deleteDialog.show" persistent max-width="1080">
      <v-card class="pa-5">
        <v-card-title class="headline pl-3 ml-3">
          <v-row>
            <v-col cols="12" align="end">
              <v-icon
                large
                color="textPrimary"
                @click="deleteDialog.show = false"
              >
                fas fa-times
            </v-icon>
            </v-col>
            <v-col
              class="text-h4"
              cols="12"
              style="color: #3b495b; font-weight: bold"
            >
              Excluir Configuração de {{ currentCollector && currentCollector === 'PAYROLL' ? 'FOLHA' : currentCollector }}
            </v-col>
            <v-col class="text-h5" cols="12" style="color: #3b495b">
              Tem certeza que deseja excluir as configurações de
              {{ currentCollector && currentCollector === 'PAYROLL' ? 'FOLHA' : currentCollector }}?
            </v-col>
          </v-row>
        </v-card-title>

        <v-card-actions class="mt-10">
          <v-row class="mt-6">
            <v-spacer></v-spacer>
            <v-btn
              class="mr-3 pa-7 btn-dialog"
              color="#3B495B"
              outlined
              width="200px"
              @click="deleteDialog.show = false"
            >
              Cancelar
            </v-btn>
            <v-btn
              class="mr-6 pa-7 btn-dialog"
              color="#3B495B"
              width="200px"
              style="color: white"
              @click="onClickDelete()"
            >
              OK
            </v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <div>
      <FTP
        v-if="currentCollector === 'FTP'"
        :isLast="allIsConfigured"
        @onClickNext="onClickNext()"
        @onClickSave="onClickSave()"
        @onClickEdit="onClickEdit()"
      />
      <EMAIL
        v-if="currentCollector === 'EMAIL'"
        :isLast="allIsConfigured"
        @onClickNext="onClickNext()"
        @onClickSave="onClickSave()"
        @onClickEdit="onClickEdit()"
      />
      <API
        v-if="currentCollector === 'API'"
        :isLast="allIsConfigured"
        @onClickNext="onClickNext()"
        @onClickSave="onClickSave()"
        @onClickEdit="onClickEdit()"
      />
      <PAYROLL
        v-if="currentCollector === 'PAYROLL'"
        :isLast="allIsConfigured"
        @onClickNext="onClickNext()"
        @onClickSave="onClickSave()"
        @onClickEdit="onClickEdit()"
      />
      <DRIVE
        v-if="currentCollector === 'DRIVE'"
        :isLast="allIsConfigured"
      />
      <SHAREPOINT
        v-if="currentCollector === 'SHAREPOINT'"
        :isLast="allIsConfigured"
      />
      <WEBSERVICE
        v-if="currentCollector === 'WEBSERVICE'"
        :isLast="allIsConfigured"
      />
    </div>

    <v-snackbar
      v-model="snackbar.show"
      timeout="10000"
      top
      elevation="24"
      :color="snackbar.type"
    >
      <div class="text-center">{{ snackbar.text }}</div>
      <template v-slot:action="{ attrs }">
        <v-icon
          text
          dark
          v-bind="attrs"
          @click="snackbar.show = false"
        >
          close
        </v-icon>
      </template>
    </v-snackbar>

    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="120">
        <div>Carregando...</div>
      </v-progress-circular>
    </v-overlay>
  </v-container>
</template>
<script>
import CollectorService from '@/services-http/sdi/CollectorService';
import ContractService from '@/services-http/contract/ContractService';
import CollectorIntegrationService from '@/services-http/sdi/CollectorIntegrationService';
import PayrollService from '@/services-http/payroll/PayrollService';
import FTP from '../collectors-types/ftp/Ftp.vue';
import EMAIL from '../collectors-types/email/Email.vue';
import API from '../collectors-types/api/Api.vue';
import PAYROLL from '../collectors-types/payroll/Payroll.vue';
import DRIVE from '../collectors-types/drive/Drive.vue';
import SHAREPOINT from '../collectors-types/sharepoint/Sharepoint.vue';
import WEBSERVICE from '../collectors-types/webservice/Webservice.vue';

export default {
  name: 'CollectorIntegration',
  components: {
    FTP,
    EMAIL,
    API,
    PAYROLL,
    DRIVE,
    SHAREPOINT,
    WEBSERVICE,
  },
  data() {
    return {
      step: 1,
      isEdit: false,
      collector: {},
      collectorType: '',
      allIsConfigured: false,
      notIncludeCollectorInFinalStep: false,
      configsLength: null,
      currentCollector: '',
      configs: [],
      contracts: [],
      subContracts: [],
      overlay: false,
      snackbar: {
        show: false,
        text: '',
        type: '',
      },
      deleteDialog: { show: false },
      insertMoreCollectorsDialog: { show: false },
      loadingContracts: false,
      loadingSubContracts: false,
    };
  },
  watch: {
    step(newValue) {
      this.currentCollector = this.collector.types[newValue - 1];
      this.allIsConfigured = this.collector.types.length <= newValue;
    },
    'collector.types': {
      handler() {
        this.verifyLastStep();
      },
      deep: true,
    },
  },
  methods: {
    async onClickSave() {
      this.overlay = true;
      const data = JSON.parse(sessionStorage.getItem('collectorsConfigs'));
      // eslint-disable-next-line prefer-destructuring
      const collector = JSON.parse(sessionStorage.getItem('collector')).collector;
      const ftpPath = collector.pathCollectedFile;
      data.forEach((element) => {
        if (element.type === 'FTP') {
          // eslint-disable-next-line no-param-reassign
          element.pathCollectedFile = ftpPath;
        }
      });

      const payload = { configs: [...data], ...collector };
      delete payload.types;
      delete payload.pathCollectedFile;

      if (payload.entity === 'FINANCIAL_GROUP') {
        if (!payload.contract.benefit && !payload.contract.carrierName && !payload.contract.id && !payload.contract.legalName && !payload.contract.policy && !payload.contract.subContractNumber) {
          payload.contract = null;
        }
        if (!payload.subContract.benefit && !payload.subContract.carrierName && !payload.subContract.id && !payload.subContract.legalName && !payload.subContract.policy && !payload.subContract.subContractNumber) {
          payload.subContract = null;
        }
      }

      await this.collectorService.Insert(payload).then(() => {
        this.payrollService.ReloadContext();
        this.overlay = false;
        this.customizeSnackbarMessage('success', 'Coletor(res) inseridos com sucesso');
        setTimeout(() => {
          this.$router.push({ name: 'Collectors' });
        }, 2000);
      }).catch(() => {
        this.overlay = false;
      });
    },
    async onClickEdit() {
      this.overlay = true;
      const data = JSON.parse(sessionStorage.getItem('collectorsConfigs'));
      // eslint-disable-next-line prefer-destructuring
      const collector = JSON.parse(sessionStorage.getItem('editCollector')).collector ? JSON.parse(sessionStorage.getItem('editCollector')).collector : JSON.parse(sessionStorage.getItem('editCollector'));
      const ftpPath = collector.pathCollectedFile;
      data.forEach((element) => {
        if (element.type === 'FTP') {
          const collectorConfigs = JSON.parse(sessionStorage.getItem('editCollector')).configs;
          const collectorId = collectorConfigs.filter((item) => item.type === 'FTP' && item.id);
          // eslint-disable-next-line no-param-reassign
          element.pathCollectedFile = ftpPath;
          if (collectorId[0]) {
            // eslint-disable-next-line no-param-reassign
            element.id = collectorId[0].id;
          }
        }
        if (element.type === 'EMAIL') {
          const collectorConfigs = JSON.parse(sessionStorage.getItem('editCollector')).configs;
          const collectorId = collectorConfigs.filter((item) => item.type === 'EMAIL' && item.id);
          if (collectorId[0]) {
            // eslint-disable-next-line no-param-reassign
            element.id = collectorId[0].id;
          }
        }
        if (element.type === 'PAYROLL') {
          const collectorConfigs = JSON.parse(sessionStorage.getItem('editCollector')).configs;
          const collectorId = collectorConfigs.filter((item) => item.type === 'PAYROLL' && item.id);
          if (collectorId[0]) {
            // eslint-disable-next-line no-param-reassign
            element.id = collectorId[0].id;
          }
        }
        if (element.type === 'API') {
          const collectorConfigs = JSON.parse(sessionStorage.getItem('editCollector')).configs;
          const collectorId = collectorConfigs.filter((item) => item.type === 'API' && item.id);
          if (collectorId[0]) {
            // eslint-disable-next-line no-param-reassign
            element.id = collectorId[0].id;
          }
        }
      });
      if (collector.configs.length > 0) {
        delete collector.configs;
      }

      const payload = { configs: [...data], ...collector };
      delete payload.types;
      delete payload.pathCollectedFile;

      await this.collectorService.Update(payload).then(() => {
        this.payrollService.ReloadContext();
        this.overlay = false;
        this.customizeSnackbarMessage('success', 'Coletor editado com sucesso');
        setTimeout(() => {
          this.$router.push({ name: 'Collectors' });
        }, 1000);
      }).catch(() => {
        this.overlay = false;
        this.customizeSnackbarMessage('error', 'Ocorreu um erro ao tentar editar o coletor');
      });
    },
    onClickNext() {
      // eslint-disable-next-line no-plusplus
      this.step++;
    },
    onClickDelete() {
      if (this.step === 1) {
        this.deleteFtp();
        this.deleteEmail();
        this.deletePayroll();
        this.deleteApi();
      } else if (this.step === 2) {
        if (this.configs.length > 1) {
          this.deleteFtp();
          this.deleteEmail();
          this.deletePayroll();
          this.deleteApi();
        } else {
          this.collector.types.pop();
          this.configs.pop();
          // eslint-disable-next-line prefer-destructuring
          this.currentCollector = this.collector.types[0];
          this.verifyLastStep();
        }
      }
      this.deleteDialog.show = false;
    },
    async deleteFtp() {
      this.overlay = true;
      if (this.currentCollector === 'FTP') {
        // eslint-disable-next-line prefer-destructuring
        const configs = JSON.parse(
          sessionStorage.getItem('editCollector'),
        ).configs;
        const ftp = configs.find((item) => item.type === 'FTP');
        if (ftp.id) {
          await this.collectorIntegrationService.DeleteConfigById(ftp.id).then(() => {
            this.overlay = false;
            this.customizeSnackbarMessage('success', 'Coletor excluído com sucesso');
            this.removeCollectorConfig();
          }).catch(() => {
            this.overlay = false;
            this.customizeSnackbarMessage('error', 'Ocorreu um erro ao tentar excluir o coletor');
          });
        } else {
          this.removeCollectorConfig();
        }
      }
    },
    async deletePayroll() {
      this.overlay = true;
      if (this.currentCollector === 'PAYROLL') {
        // eslint-disable-next-line prefer-destructuring
        const configs = JSON.parse(sessionStorage.getItem('editCollector')).configs;
        const payroll = configs.find((item) => item.type === 'PAYROLL');
        if (payroll.id) {
          await this.collectorIntegrationService.DeleteConfigById(payroll.id).then(() => {
            this.overlay = false;
            this.customizeSnackbarMessage('success', 'Coletor excluído com sucesso');
            this.removeCollectorConfig();
          }).catch(() => {
            this.overlay = false;
            this.customizeSnackbarMessage('error', 'Ocorreu um erro ao tentar excluir o coletor');
          });
        } else {
          this.removeCollectorConfig();
        }
      }
    },
    async deleteEmail() {
      this.overlay = true;
      if (this.currentCollector === 'EMAIL') {
        // eslint-disable-next-line prefer-destructuring
        const configs = JSON.parse(sessionStorage.getItem('editCollector')).configs;
        const email = configs.find((item) => item.type === 'EMAIL');
        if (email.id) {
          await this.collectorIntegrationService.DeleteConfigById(email.id).then(() => {
            this.overlay = false;
            this.customizeSnackbarMessage('success', 'Coletor excluído com sucesso');
            this.removeCollectorConfig();
          }).catch(() => {
            this.overlay = false;
            this.customizeSnackbarMessage('error', 'Ocorreu um erro ao tentar excluir o coletor');
          });
        } else {
          this.removeCollectorConfig();
        }
      }
    },
    async deleteApi() {
      this.overlay = true;
      if (this.currentCollector === 'API') {
        const collector = JSON.parse(sessionStorage.getItem('editCollector')).configs;
        const api = collector.find((item) => item.type === 'API');
        if (api.id) {
          await this.collectorIntegrationService.DeleteConfigById(api.id).then(() => {
            this.payrollService.ReloadContext();
            this.overlay = false;
            this.customizeSnackbarMessage('success', 'Coletor excluído com sucesso');
            this.removeCollectorConfig();
          }).catch(() => {
            this.overlay = false;
            this.customizeSnackbarMessage('error', 'Ocorreu um erro ao tentar excluir o coletor');
          });
        } else {
          this.removeCollectorConfig();
        }
      }
    },
    removeCollectorConfig() {
      if (this.step === 1) {
        this.collector.types.shift();
        this.configs.shift();
        // eslint-disable-next-line prefer-destructuring
        this.currentCollector = this.collector.types[0];
        this.verifyLastStep();
      } else if (this.step === 2) {
        this.collector.types.pop();
        this.configs.pop();
        // eslint-disable-next-line prefer-destructuring
        this.currentCollector = this.collector.types[0];
        this.verifyLastStep();
      }
    },
    loadCollector() {
      sessionStorage.setItem('collectorsConfigs', JSON.stringify(this.configs));
      this.collector = JSON.parse(sessionStorage.getItem('collector')).collector;
      this.collectorType = JSON.parse(sessionStorage.getItem('collector')).collectorType;
      // eslint-disable-next-line prefer-destructuring
      this.currentCollector = this.collector.types[0];
      if (this.collectorType === 'client') {
        this.loadContractsByFinancialGroupId();
      }
    },
    loadCollectorsEdit() {
      const collector = JSON.parse(sessionStorage.getItem('editCollector'));
      this.configs = collector.configs;
      sessionStorage.setItem('collectorsConfigs', JSON.stringify(this.configs));

      this.collectorType = collector.entity === 'FINANCIAL_GROUP' ? 'client' : 'carrier';
      const types = collector.configs.map((config) => {
        const listTypes = [];
        listTypes.push(config.type);
        return listTypes;
      });
      // eslint-disable-next-line prefer-destructuring
      this.currentCollector = Object.values(types[0])[0];
      this.collector = collector;
      this.collector.types = Object.values(types).toString().split(',');
      if (this.collectorType === 'client') {
        this.loadContractsByFinancialGroupId();
      }
    },
    verifyLastStep() {
      this.allIsConfigured = this.collector.types.length <= this.step;
      this.notIncludeCollectorInFinalStep = this.collector.types.length > 1;
      this.configsLength = this.collector.types.length;
    },
    async loadContractsByFinancialGroupId() {
      if (this.collector.financialGroup.id) {
        this.loadingContracts = true;
        this.contracts = [];
        this.subContracts = [];
        const query = `financialGroupIds=${this.collector.financialGroup.id}`;
        this.contractService.FindAllByFilters(query).then((response) => {
          if (response && response.data && response.data.length > 0) {
            this.contracts = response.data.filter((c) => c.subcontract === false);
            this.loadingContracts = false;
          }
          if (this.collector.contract.id) {
            this.loadSubContractsByContractId();
          }
        }).catch(() => {
          this.loadingContracts = false;
        });
      }
    },
    contructorContractDescription(contractId) {
      let text = '';
      if (contractId) {
        const contractName = this.contracts.find((c) => c.id === contractId);
        text = `${(contractName && contractName.benefit_id && contractName.benefit_id.name) ? contractName.benefit_id.name : (contractName && contractName.benefit_id) ? contractName.benefit_id : '-'} - ${(contractName && contractName.carrier_id) ? contractName.carrier_id.xipp_commercial_name : '-'} - ${(contractName && contractName.policy) ? contractName.policy : '-'} - ${(contractName && contractName.contract_owner_id && contractName.contract_owner_id.legal_name) ? contractName.contract_owner_id.legal_name : '-'}`;
      }
      return text;
    },
    async loadSubContractsByContractId() {
      if (this.collector.contract.id) {
        this.loadingSubContracts = true;
        this.contractService.FindByParentId(this.collector.contract.id).then((response) => {
          if (response && response.data && response.data.length > 0) {
            this.subContracts = response.data.filter((c) => c.subcontract === true);
          }
          this.loadingSubContracts = false;
        }).catch((error) => {
          this.loadingSubContracts = false;
        });
      }
    },
    contructorSubContractDescription(subContractId) {
      let text = '';
      if (subContractId) {
        const subContractName = this.subContracts.find((c) => c.id === subContractId);
        text = `${(subContractName && subContractName.subcontract_number) ? subContractName.subcontract_number : '-'} - ${(subContractName && subContractName.contract_owner_id && subContractName.contract_owner_id.legal_name) ? subContractName.contract_owner_id.legal_name : '-'}`;
      }
      return text;
    },
    customizeSnackbarMessage(type, text) {
      this.snackbar.show = true;
      this.snackbar.type = type;
      this.snackbar.text = text;
    },
  },
  mounted() {
    this.isEdit = !!this.$route.query.isEdit;
    if (this.isEdit) {
      this.loadCollectorsEdit();
    } else {
      this.loadCollector();
    }
    this.verifyLastStep();
  },
  created() {
    this.collectorService = new CollectorService();
    this.contractService = new ContractService();
    this.collectorIntegrationService = new CollectorIntegrationService();
    this.payrollService = new PayrollService();
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/css/stepper.scss";
</style>
