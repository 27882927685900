<template>
  <div>
    <v-row class="mb-3">
      <h2>Tipo de Integração: <b>Email</b></h2>
    </v-row>
    <v-tabs
      color="primary"
      background-color="var(--v-background-base)"
      v-model="tab"
      class="mb-4"
    >
      <v-tab style="font-size: 22px">Recebimento</v-tab>
    </v-tabs>

    <v-tabs-items
      v-model="tab"
      grow
      style="background-color: var(--v-background-base)"
    >
      <v-tab-item>
        <v-form ref="form" lazy-validation>
          <v-row>
            <v-col cols="2"><label class="label">URL (hostname)*</label></v-col>
            <v-col cols="4"
              ><v-text-field
                outlined
                label="Hostname"
                v-model="emailParameter.receiver.url"
                :rules="[rule.required]"
              ></v-text-field
            ></v-col>
          </v-row>
          <v-row>
            <v-col cols="2"><label class="label">Porta*</label></v-col>
            <v-col cols="4"
              ><v-text-field
                outlined
                label="Porta"
                type="number"
                v-model="emailParameter.receiver.port"
                :rules="[rule.required]"
              ></v-text-field
            ></v-col>
          </v-row>
          <v-row>
            <v-col cols="2"><label class="label">Usuário*</label></v-col>
            <v-col cols="4"
              ><v-text-field
                outlined
                label="Usuário"
                v-model="emailParameter.receiver.user"
                :rules="[rule.required]"
              ></v-text-field
            ></v-col>
          </v-row>
          <v-row>
            <v-col cols="2"><label class="label">Senha*</label></v-col>
            <v-col cols="4">
              <v-text-field
                v-model="emailParameter.receiver.password"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[rule.required]"
                :type="showPassword ? 'text' : 'password'"
                label="Senha"
                outlined
                name="input-10-1"
                @click:append="showPassword = !showPassword"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="2"><label class="label">Folder*</label></v-col>
            <v-col cols="4"
              ><v-text-field
                outlined
                label="Folder"
                v-model="emailParameter.receiver.folder"
                :rules="[rule.required]"
              ></v-text-field
            ></v-col>
          </v-row>
          <v-row>
            <v-col cols="2">
              <div>
                <label class="label">Protocolo</label>
              </div>
            </v-col>
            <v-col cols="4">
              <v-btn-toggle v-model="emailParameter.receiver.protocol">
                <div style="background-color: var(--v-background-base)">
                  <v-btn
                    small
                    active-class="primary"
                    min-width="158"
                    value="POP3"
                    class="mr-6"
                    >POP3</v-btn
                  >
                  <v-btn
                    small
                    active-class="primary"
                    min-width="158"
                    value="IMAP"
                    class="mr-6"
                    >IMAP</v-btn
                  >
                </div>
              </v-btn-toggle></v-col
            >
          </v-row>
          <v-row>
            <v-col cols="2">
              <div>
                <label class="label">Deletar E-mails do Servidor</label>
              </div>
            </v-col>
            <v-col cols="4">
              <v-btn-toggle v-model="emailParameter.receiver.deleteFile">
                <div style="background-color: var(--v-background-base)">
                  <v-btn
                    small
                    active-class="primary"
                    min-width="158"
                    :value="true"
                    class="mr-6"
                    >Sim</v-btn
                  >
                  <v-btn
                    small
                    active-class="primary"
                    min-width="158"
                    :value="false"
                    class="mr-6"
                    >Não</v-btn
                  >
                </div>
              </v-btn-toggle></v-col
            >
          </v-row>
        </v-form>
      </v-tab-item>
    </v-tabs-items>
    <v-footer>
      <v-row class="d-flex justify-end mt-10">
        <v-btn
          class="mr-6"
          x-large
          outlined
          color="textPrimary"
          width="250px"
          @click="onClickCancel()"
        >
          Cancelar
        </v-btn>
        <v-btn
          v-if="this.isLast"
          class="white--text mr-10"
          x-large
          color="textPrimary"
          width="250px"
          @click="isEdit ? onClickEdit() : onClickSave()"
        >
          Salvar
        </v-btn>
        <v-btn
          v-else
          class="white--text mr-10"
          x-large
          color="textPrimary"
          width="250px"
          @click="onClickNext()"
        >
          Próximo
        </v-btn>
      </v-row>
    </v-footer>
  </div>
</template>
<script>
import Rules from '@/shared/validators/formRules';

export default {
  name: 'Email',
  props: {
    isLast: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      tab: null,
      isEdit: false,
      configs: [],
      editConfigs: [],
      showPassword: false,
      emailParameter: {
        receiver: {
          deleteFile: true,
          folder: '',
          password: '',
          port: '',
          protocol: 'POP3',
          url: '',
          user: '',
        },
        status: 'ENABLED',
      },
    };
  },
  mounted() {
    this.verifyCollectorEdit();
  },

  methods: {
    onClickCancel() {
      this.$router.push('/collectors');
    },

    onClickNext() {
      if (this.$refs.form.validate()) {
        if (this.isEdit) {
          const collectorsConfigs = [];
          this.emailParameter.status = 'ENABLED';
          const emailParameter = {
            name: this.configs.name,
            type: this.configs.type,
            emailParameter: this.emailParameter,
          };

          delete this.configs.ftpParameter;
          collectorsConfigs.push(emailParameter);

          sessionStorage.setItem('collectorsConfigs', JSON.stringify(collectorsConfigs));
        } else {
          this.emailParameter.status = 'ENABLED';
          const emailParameter = {
            name: JSON.parse(sessionStorage.getItem('collector')).collectorName,
            type: 'EMAIL',
            emailParameter: this.emailParameter,
          };
          this.configs.push(emailParameter);
          sessionStorage.setItem(
            'collectorsConfigs',
            JSON.stringify(this.configs),
          );
        }
        this.$emit('onClickNext');
      }
    },

    onClickSave() {
      if (this.$refs.form.validate()) {
        this.emailParameter.status = 'ENABLED';
        const emailParameter = {
          name: JSON.parse(sessionStorage.getItem('collector')).collectorName,
          type: 'EMAIL',
          emailParameter: this.emailParameter,
        };
        this.configs.push(emailParameter);
        sessionStorage.setItem(
          'collectorsConfigs',
          JSON.stringify(this.configs),
        );
        this.$emit('onClickSave');
      }
    },

    onClickEdit() {
      if (this.$refs.form.validate()) {
        let emailParameter = {};
        const collectorsConfigs = JSON.parse(sessionStorage.getItem('collectorsConfigs'));
        this.emailParameter.status = 'ENABLED';
        emailParameter = {
          name: collectorsConfigs[0].name,
          type: 'EMAIL',
          emailParameter: this.emailParameter,
        };

        collectorsConfigs.push(emailParameter);
        sessionStorage.setItem('collectorsConfigs', JSON.stringify(collectorsConfigs));
        this.$emit('onClickEdit');
      }
    },
    verifyCollectorEdit() {
      if (this.$route.query.isEdit) {
        this.isEdit = true;
        const session = JSON.parse(sessionStorage.getItem('editCollector'));
        const found = session.configs.find((config) => config.type === 'EMAIL');
        if (found) {
          // eslint-disable-next-line prefer-destructuring
          this.configs = found;
          if (this.configs) {
            this.emailParameter = this.configs.emailParameter;
          }
        } else {
          // eslint-disable-next-line no-unused-expressions
          this.configs = JSON.parse(sessionStorage.getItem('collectorsConfigs'));
          this.editConfigs = JSON.parse(sessionStorage.getItem('collectorsConfigs'));
        }
      } else {
        this.configs = JSON.parse(sessionStorage.getItem('collectorsConfigs'));
      }
    },
  },
  created() {
    this.rule = new Rules();
  },
};
</script>
